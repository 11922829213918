import { graphql, useStaticQuery } from "gatsby"

const useProjects = () => {
	const data = useStaticQuery(graphql`
		query Yo {
			allMarkdownRemark(
				filter: { frontmatter: { template: { eq: "projects" } } }
			) {
				nodes {
					frontmatter {
						title
						slug
						link
						thumbnail {
							childImageSharp {
								fluid(maxWidth: 300) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					excerpt
				}
			}
		}
	`)
	return data.allMarkdownRemark.nodes.map((project) => ({
		title: project.frontmatter.title,
		slug: project.frontmatter.slug,
		thumbnail: project.frontmatter.thumbnail,
		link: project.frontmatter.link,
		excerpt: project.excerpt,
	}))
}

export default useProjects
