import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import global from "../css/globalVariables"

const Project = styled.a`
	&& {
		display: flex;
		align-items: center;
		flex-direction: column;
		color: ${(props) => props.theme.font};
		background-color: ${(props) => props.theme.bgLight};
		max-width: 300px;
		padding: 10px;
		border-radius: 4px;
		text-align: center;
		&:nth-child(even) {
			margin: 15px 0;
		}

		@media screen and (${global.mediaDesktop}) {
			&:nth-child(even) {
				margin: 0 15px;
			}
		}
	}
`

const ProjectDescription = styled.p`
	font-size: 0.9rem;
	margin: 0;
`

const ProjectTitle = styled.h3`
	&& {
		color: ${(props) => props.theme.link};
		margin: 10px 0;
	}
`
const Logo = styled(Img)`
	width: 100%;
	height: auto;
	object-fit: contain;
`

const ProjectView = ({ project }) => {
	return (
		<Project alt={project.title} href={project.link}>
			<Logo
				fluid={project.thumbnail.childImageSharp.fluid}
				alt={project.thumbnail.childImageSharp.originalName}
			/>
			<ProjectTitle>{project.title}</ProjectTitle>
			<ProjectDescription>{project.excerpt}</ProjectDescription>
		</Project>
	)
}

export default ProjectView
