import React from "react"
import Layout from "../layout/layout"
import useProjects from "../hooks/useProjects"
import ProjectView from "../components/Project"
import styled from "styled-components"
import global from "../css/globalVariables"
import config from "../../data/SiteConfig"
import SEO from "../components/SEO"
import { Helmet } from "react-helmet"

const ProjectSection = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;

	@media screen and (${global.mediaDesktop}) {
		align-items: flex-start;
		flex-direction: row;
	}
`
const ProjectsPage = () => {
	const data = useProjects()

	return (
		<Layout>
			<Helmet>
				<title>{`Projects - ${config.siteTitle}`}</title>
				<SEO description="Client projects that I made since I became a freelancer. Wanna be in this ? Then contact me! " />
			</Helmet>
			<h1>Projects</h1>
			<ProjectSection>
				{data.map((project) => (
					<ProjectView key={project.slug} project={project} />
				))}
			</ProjectSection>
		</Layout>
	)
}

export default ProjectsPage
